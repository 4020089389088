import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/pages/Home.css';
import '../assets/styles/pages/Global.css';


function Home() {
    return (
        <div className="page-main secondary-bg">
            <div className="home-banner">
                <div className="home-banner-text">
                    High Quality Excavation Services at the Right Price
                </div>
            </div>
            <div className="home-info">
                <div className="font-bold font-header">
                    Excavating Company in the Bitterroot Valley, MT
                </div>
                <div className="subtext font-body">
                    Established in 2020, BP Excavating & Landscaping is proud to be
                    a premier excavating service, serving:
                    <ul>
                        <li>Missoula</li>
                        <li>Lolo</li>
                        <li>Florence</li>
                        <li>Stevensville</li>
                        <li>Corvallis</li>
                        <li>Hamilton</li>
                        <li>Darby</li>
                        <li>Sula</li>
                    </ul>
                    We offer a full range of earthmoving services, including general excavation,
                    trenching, septic excavation, water line installation, and much more. If you
                    need some dirt moved or a hole dug, we're the ones to do it!
                </div>
            </div>
            <div className="home-info-banner" />
            <div className="home-more-info">
                <div className="cards">
                    <div className="card">
                        <div className="card-title font-header font-bold">
                            High Quality Workmanship
                        </div>
                        <div className="card-text font-body">
                            BP Excavation & Landscaping adapts quickly to working alongside other crews
                            and is accustomed to meeting strict deadlines with staying on budget. Our
                            well maintained machinery will be used to make the highest quality of service
                            is provided with each project.
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-title font-header font-bold">
                            Fully Insured and Bonded
                        </div>
                        <div className="card-text font-body">
                            BP Excavation & Landscaping excavation is highly trained and regularly updated
                            on applying the strictest of safety standards according to Montana codes. Your
                            job site and property are always protected regardless of the size and complexity
                            of the project.
                        </div>
                    </div>
                </div>
                <div className="contact font-body font-bold">
                    <Link to="/contact"> Contact Us Today For A Free Estimate </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;