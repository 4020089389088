import React, { useLayoutEffect } from "react";
import '../assets/styles/pages/Contact.css';
import '../assets/styles/pages/Global.css';

function Contact() {

    const actionUrl = "https://formsubmit.co/8cc08fb3a20fd34f07e629250a46f040";
    const redirectUrl = "https://www.bp-excavation.net/contact";

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="page-main secondary-bg">
            <div className="main-contact">
                <div className="columns">
                    <div className="contact-details">
                        <div className="title-font">
                            Contact Us Later
                        </div>
                        <div className="details">
                            <div className="detail-item">
                                <div id="content">BP Excavation & Landscaping</div>
                                <div id="content">4597 Torrey Lane</div>
                                <div id="content">Stevensville, MT 59870</div>
                            </div>
                            <div className="detail-item">
                                (406)-546-4913
                            </div>
                            <div className="detail-item">
                                bpexandl@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="contact-form">
                        <div className="form_title title-font">Contact Us Now</div>
                        <form className="form details" acceptCharset="utf-8" action={ actionUrl } method="post">
                            <input required name="name" v-model='contact.name' placeholder="Name" type="text" autoComplete="nope" />
                            <input required name="email" v-model="contact.email" placeholder="E-mail" type="email" autoComplete="nope" />
                            <input required name="phone" v-model="contact.phone" placeholder="Phone Number" type="tel" autoComplete="nope" />
                            <input required name="address" v-model='contact.address' placeholder="Address" type="text" autoComplete="nope" />
                            <input type="hidden" name="_next" value={ redirectUrl } />
                            <input type="text" name="_honey" style={{ display:"none" }} />
                            <textarea name="message" v-model="contact.message" rows="4" placeholder="Message"></textarea>
                            <button className="button">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;