import '../../assets/styles/index.css';
import '../../assets/styles/components/Navbar/VerticalMenu.css';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

function VerticalMenu() {

    const hamburgerTransition = () => {
        var x = document.getElementById("myLinks");
        if (x.style.display === "block") {
          x.style.display = "none";
        } else {
          x.style.display = "block";
        }
    }

    return (
        <>
            <div className="topnav">
                <Link to="/">
                    <img className="vertical-img" src={logo} alt="logo" />
                </Link>
                <div className="icon black-color" onClick={hamburgerTransition}>
                    <i className="fa fa-bars fa-2x"></i>
                </div>
            </div>
            <div className="dropdown-nav primary-bg">
                <div id="myLinks">
                    <Link className="black-color" to="/" onClick={hamburgerTransition}> Home </Link>
                    <Link className="black-color" to="/excavation" onClick={hamburgerTransition}> Excavation </Link>
                    <Link className="black-color" to="/landscaping" onClick={hamburgerTransition}> Landscaping </Link>
                    <Link className="black-color" to="/contact" onClick={hamburgerTransition}> Contact Us</Link>
                </div>
            </div>
        </>
    );
}

export default VerticalMenu;