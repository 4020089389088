import '../assets/styles/components/Footer.css';


function Footer() {
  return (
    <div className="footer-main primary-accent-bg">
      <div className="footer-content">
        <div className="column white-color font-body">
          (406)-546-4913
        </div>
        <div className="column white-color font-body">
          bpexandl@gmail.com
        </div>
        <div className="column white-color font-body">
          4597 Torrey Lane, Stevensville, MT 59870
        </div>
      </div>
    </div>
  );
}

export default Footer;
