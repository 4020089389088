import React from 'react';
import '../assets/styles/pages/Landscaping.css';
import '../assets/styles/pages/Global.css';


function Landscaping() {
    return (
        <div className="page-main secondary-bg">
            <section className="landscaping-image0 landscaping-background conditional-background"></section>
            <section className="landscaping-text">
                <div className="landscaping-header font-header font-bold">
                    Landscaping Services in the Bitterroot Valley
                </div>
                <div className="landscaping-content font-body">
                    The outdoor areas of your property are just as
                    important as the indoor ones. After all, that's
                    the first thing people see when they pass by. At
                    BP Excavation & Landscape, we understand
                    the importance of keeping your garden or lawn in
                    good shape; it sends the message to passersby that
                    you care about the finer things. As a detail-oriented
                    company, BP & Landscape is perfectly positioned
                    to take care of all your landscaping needs.
                </div>
            </section>
            <section className="landscaping-image0 landscaping-background"></section>
            <section className="landscaping-text">
                <div className="landscaping-content font-body">
                    Landscaping is about more than just cutting grass or planting
                    a new garden, it involves all of the natural elements of your
                    outdoor area. Think of it as a blank canvas, just waiting for
                    you to create something beautiful on it!
                </div>
            </section>
            <section className="landscaping-image1 landscaping-background"></section>
            <section className="landscaping-text">
                <div className="landscaping-content font-body">
                    BP Excavation & Landscape is experienced in all aspects of
                    landscaping, ranging from the planting and upkeep of plants,
                    the mowing of lawns, and installation and maintenance of
                    irrigation systems, to keep your lawn green and lush day in and day out!
                </div>
            </section>
            <section className="landscaping-image1 landscaping-background conditional-background"></section>
        </div>
    );
}

export default Landscaping;