import '../../assets/styles/components/Navbar/Navbar.css';
import HorizontalMenu from './HorizontalMenu';
import VerticalMenu from './VerticalMenu';

function Navbar() {

  return (
    <div className="navbar-main secondary-bg">
        <div className="horizontal-menu">
            <HorizontalMenu/>
        </div>
        <div className="vertical-menu">
            <VerticalMenu/>
        </div>
        <div className="contact-button">
            <i className="fa fa-phone fa-lg"></i>
            <p>(406)-546-4913</p>
        </div>
    </div>
  );
}

export default Navbar;
