import '../../assets/styles/index.css';
import '../../assets/styles/components/Navbar/HorizontalMenu.css';
import logo from '../../assets/images/logo.png';
import { Link, useLocation } from 'react-router-dom';

function HorizontalMenu() {

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
        <div className="horizontal-nav">
            <ul className="horizontal-ul">
                <li className="horizontal-li">
                    <Link to="/">
                        <img className="logo" src={logo} alt="logo"/>
                    </Link>
                </li>
                <li className={splitLocation[1] === "" ? "active nav-text horizontal-li" : "nav-text horizontal-li"}>
                    <Link className="black-color" to="/"> Home </Link>
                </li>
                <li className={splitLocation[1] === "excavation" ? "active nav-text horizontal-li" : "nav-text horizontal-li"}>
                    <Link className="black-color" to="/excavation"> Excavation </Link>
                </li>
                <li className={splitLocation[1] === "landscaping" ? "active nav-text horizontal-li" : "nav-text horizontal-li"}>
                    <Link className="black-color" to="/landscaping"> Landscaping </Link>
                </li>
                <li className={splitLocation[1] === "contact" ? "active nav-text horizontal-li" : "nav-text horizontal-li"}>
                    <Link className="black-color" to="/contact"> Contact Us</Link>
                </li>
            </ul>
        </div>
    );
}

export default HorizontalMenu;